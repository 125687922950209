import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';
import { SalaryTableReference } from '@models/salary-table/salary-table-reference';
import { SalaryTable } from '@models/salary-table/salary-table';
import { CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-salary-table',
  templateUrl: './salary-table.component.html',
  styleUrls: ['./salary-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CurrencyPipe,
  ],
})
export class SalaryTableComponent {
  content = input<SalaryTableReference>();
  salaryTable = computed<SalaryTable | undefined>(() => {
    return this.content()?.salaryTable;
  });
}
