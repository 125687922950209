import { ImageWithAltText } from '@models/image/image-with-alt-text';
import {
  ImageWithAltTextComponent,
} from '@shared/components/content/image-with-alt-text/image-with-alt-text.component';
import { AssetHelper } from '@core/helpers/asset.helper';
import { ContentHandlerInterface, DynamicContentInterface } from '@halloverden/ngx-directus-content-handler';
import { RelationContent } from '@halloverden/ngx-directus-content-handler/lib/interfaces/content/relation-content';

const supportedContentTypes = [
  'image_with_alt_text',
];

export class ImageWithAltTextContentHandler implements ContentHandlerInterface {
  handle(content: any): any {
    return this.makeImageWithAltText(content as RelationContent<ImageWithAltText>);
  }

  supports(type: string): boolean {
    return supportedContentTypes.includes(type);
  }

  getPriority(): number {
    return 0;
  }

  makeImageWithAltText(content: RelationContent<ImageWithAltText>): DynamicContentInterface {
    const imageWithAltText = content.item;

    if (null === imageWithAltText) {
      return {
        text: 'blep',
      };
    }

    return {
      component: {
        type: ImageWithAltTextComponent,
        inputs: {
          src: AssetHelper.getAssetUrl(imageWithAltText.image.id),
          altText: imageWithAltText.altText || '',
          srcset: '350w, 392w, 536w, 576w, 710w',
          sizes: `
            (min-width: 1300px) 710px,
            (min-width: 1024px) 536px,
            (min-width: 768px) 392px,
            (min-width: 411px) 576px,
            350px
          `,
          height: imageWithAltText.image.height || 1,
          width: imageWithAltText.image.width || 1,
        },
      },
    };
  }
}
