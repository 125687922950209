<div class="salary-table">
  <table>
    <thead>
    <tr>
      <th>Eksamensår</th>
      <th>Årslønn bachelor</th>
      <th>Årslønn master</th>
    </tr>
    </thead>

    <tbody>
      @for (row of salaryTable()?.salaryTableRows; track row.examYear) {
        <tr>
          <td>{{ row.examYear }}</td>
          <td>{{ row.salaryBachelor | currency: 'NOK' : '' : '1.0-0' : 'nb' }} kr</td>
          <td>{{ row.salaryMaster | currency: 'NOK' : '' : '1.0-0' : 'nb' }} kr</td>
        </tr>
      }
    </tbody>
  </table>
</div>
