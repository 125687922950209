import { AfterViewInit, Component, HostListener, inject, OnInit, Renderer2 } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { BodyHelper } from '@core/helpers/body.helper';

@Component({
  selector: 'app-root',
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
})
export class AppComponent implements AfterViewInit, OnInit {
  readonly #renderer = inject(Renderer2);

  private static _toggleBodyClass(keyboardNavigation: boolean): void {
    if (keyboardNavigation) {
      BodyHelper.addClass('keyboard-navigation');
    } else {
      BodyHelper.removeClass('keyboard-navigation');
    }
  }

  ngAfterViewInit(): void {
    this.#renderer.listen('body', 'click', () => {
      AppComponent._toggleBodyClass(false);
    });
  }

  ngOnInit(): void {
    // To prevent transition on page load
    // Class is removed in ThemeService
    BodyHelper.addClass('transition-prevention');
  }

  @HostListener('keyup', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent): void {
    AppComponent._toggleBodyClass(true);
  }
}
