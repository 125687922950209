<img
  [ngSrc]="src()"
  [ngSrcset]="srcset()"
  [sizes]="sizes()"
  [height]="height()"
  [width]="width()"
  [alt]="altText()"
  priority
  [class]="class()"
/>
