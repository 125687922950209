import { NgxDirectusContentHandlerConfig } from '@halloverden/ngx-directus-content-handler';
import { ImageWithAltTextContentHandler } from '@core/content-handlers/image-with-alt-text.content-handler';
import { SalaryTableReferenceContentHandler } from '@core/content-handlers/salary-table-reference.content-handler';

export const directusContentHandlerConfig: NgxDirectusContentHandlerConfig = {
  debug: false,
  contentHandlers: [
    ImageWithAltTextContentHandler,
    SalaryTableReferenceContentHandler
  ]
};
