import { SalaryTableReference } from '@models/salary-table/salary-table-reference';
import { SalaryTableComponent } from '@shared/components/salary-table/salary-table.component';
import { ContentHandlerInterface } from '@halloverden/ngx-directus-content-handler';
import { RelationContent } from '@halloverden/ngx-directus-content-handler/lib/interfaces/content/relation-content';

const supportedContentTypes = [
  'salary_table_reference',
];

export class SalaryTableReferenceContentHandler implements ContentHandlerInterface {
  handle(content: RelationContent<SalaryTableReference>): any {
    return {
      component: {
        type: SalaryTableComponent,
        inputs: {
          content: content.item,
        },
      },
    };
  }

  supports(type: string): boolean {
    return supportedContentTypes.includes(type);
  }

  getPriority(): number {
    return 0;
  }
}
