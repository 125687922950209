import { ApplicationConfig, inject, provideAppInitializer } from '@angular/core';
import { provideRouterWithConfig } from './router.config';
import { ThemeService } from '@core/services/theme.service';
import { DARK_MODE_OPTIONS } from '@core/injection-tokens/dark-mode-options.injection-token';
import { DarkModeOptions } from '@interfaces/dark-mode-options.interface';
import { provideHttpClient } from '@angular/common/http';
import { provideGraphql } from './graphql.provider';
import { provideImageLoaderConfig } from './image-loader.config';
import { ngxDirectusContentHandlerProvider } from '@halloverden/ngx-directus-content-handler';
import { directusContentHandlerConfig } from './content-handler.config';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouterWithConfig(),
    provideHttpClient(),
    provideGraphql(),
    ngxDirectusContentHandlerProvider(directusContentHandlerConfig),
    provideImageLoaderConfig(),
    provideAppInitializer(() => {
      const initializerFn = ((themeService: ThemeService) => {
        return (): Promise<any> => {
          return themeService.serviceInit();
        };
      })(inject(ThemeService));

      return initializerFn();
    }),
    {
      provide: DARK_MODE_OPTIONS,
      useValue: {
        darkModeClass: 'theme-dark',
        lightModeClass: 'theme-light',
        preloadingClass: 'theme-preloading',
        storageKey: 'color-theme',
        element: document.body,
      } as DarkModeOptions,
    },
  ],
};
