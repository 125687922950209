import { Component, input } from '@angular/core';
import { NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-image-with-alt-text',
  imports: [
    NgOptimizedImage,
  ],
  templateUrl: './image-with-alt-text.component.html',
})
export class ImageWithAltTextComponent {
  src = input.required<string>();

  altText = input.required<string>();

  class = input<string>();

  srcset = input.required<string>();

  sizes = input.required<string>();

  height = input.required<number>();

  width = input.required<number>();
}
